import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';


function MyComponent() {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState(null);
  const [textFieldValue6, setTextFieldValue6] = useState('');
  const [max, setMax] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(null);


  const insertU = async () => {
    setData([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/dnevnikU.php', { usev: textFieldValue1,datum: selectedFromDate,sredstvo:textFieldValue2,radnja: textFieldValue6,kolicina:textFieldValue5 });
      setData(result.data);
      console.log(result.data);
      fetchDataPretraga();
      fetchData2();
      reset();
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); 
    }
    
    alert("Podaci uspešno uneti!");
  };

  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterUsev.php');
      setData1(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterSredstvo.php');
      setData2(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterRadnjaU.php');
      setData5(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData4 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterUsev.php');
      setData4(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataPretraga = async () => {
    setData3([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/filterDnevnikU.php',{usev:textFieldValue4});
      setData3(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);


  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };








  const handleValueReceived = (value,value2, source) => {
    if (source === 'textf1') {
      setTextFieldValue1(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value);
      setTextFieldValue5(value2);
      setMax(value2);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value2);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value2);
    }
    else if(source==='textf6'){
      setTextFieldValue6(value);
    }
  };

  
  const reset = async () => {
    setTextFieldValue1('');
    setTextFieldValue2('');
    setTextFieldValue3('');
    setTextFieldValue4(''); 
    setTextFieldValue5(''); 
    setTextFieldValue6(''); 
  };


  useEffect(() => {
    console.log(selectedFromDate);
    }, [selectedFromDate]);




  return (
<div className="wrapperFarma">
  <Sidemenu />
  <div className='filteri'>
    
<h1 className='filteriNaslov'>Dnevnik useva</h1>
<div className="search-container">
      <label htmlFor="textf1">Šifra useva</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf1"
        value={textFieldValue1}
        onChange={(e) => setTextFieldValue1(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data1, ['kultura', 'id', 'sorta', 'lokacija','datum_setve','datum_zetve','status'], "Naslov 1",'textf1')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
    <div className='datumi'>
          <label htmlFor="textf4">Datum</label>
            <DatePicker selected={selectedFromDate}
                  onSelectDate={setSelectedFromDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable  />
    </div>
    <br/>
      <label htmlFor="textf2">Sredstvo</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf2"
        value={textFieldValue2}
        onChange={(e) => setTextFieldValue2(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data2, ['sredstvo','kolicina', 'JM'], "Naslov 1", 'textf2')}
        className="search-icon"
      />
    </div>
    <br/>
    <label htmlFor="textf6">Radnja</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf5"
        value={textFieldValue6}
        onChange={(e) => setTextFieldValue6(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data5, ['Radnja'], "Naslov 1", 'textf6')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
    <label htmlFor="textf5">Količina</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf5"
        value={textFieldValue5}
        onChange={(e) => {
          const newValue = parseFloat(e.target.value);
          if (newValue < (parseFloat(max)+1) || isNaN(newValue)) {
            setTextFieldValue5(newValue);
          }
        }}
        className='numberInputFilter'
      />
    </div>
 
    </div>

    {isModalOpen && (
      <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
    )}
    <br />
    
    <div className='farmaDugmad'>
 <br />
    <button className='farmaDugme' onClick={insertU}>Unos</button>
    
    <button className='farmaDugme' onClick={reset}>Reset</button>
    

     </div>
     <div className="search-container">
      <label htmlFor="textf4">Šifra useva</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf4"
        value={textFieldValue4}
        onChange={(e) => setTextFieldValue4(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data4, ['kultura', 'id', 'sorta', 'lokacija','datum_setve','datum_zetve','status'], "Naslov 1",'textf4')}
        className="search-icon"
      />
    </div>
    </div>
 <br />
    <button className='farmaDugme' onClick={fetchDataPretraga}>Pretraga</button>
  </div>

  <div className='farmaTabelaWrapper'>
    <table className="farmaTable">
      
      <thead>
        <tr>
          <th className="freez">Id</th>
          <th className='freez'>Parcela</th>
          <th className='freez'>Sredstvo</th>
          <th className='freez'>Radnja</th>
          <th className='freez'>Datum</th>
          <th className='freez'>Količina</th>
        </tr>
      </thead>
      <tbody>
  {Array.isArray(data3) && data3.length > 0 ? (
    data3.map((order) => (
      <tr >
        <td>{order.id}</td>
        <td>{order.parcela}</td>
        <td>{order.sredstvo}</td>
        <td>{order.radnja}</td>
      <td>{new Date(order.datum).toLocaleDateString('en-GB')}</td>
        <td>{order.kolicina}</td>
      </tr>
    ))
  ) : (
    Array.from({ length: 16 }, (_, index) => (
      <tr key={index}>
      </tr>
    ))
  )}
</tbody>

    </table>
    
  {isLoading && <LoadingSpinner />}
  </div>
</div>

  );
}

export default MyComponent;
