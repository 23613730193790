import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from './Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ChildModalPopUp from './PopUp2';
import Modal from 'react-awesome-modal';

function PopUpEdit({ onClose, id, objekat, vrsta, sorta, godiste, pol, status}) {
  const [parentModal, setParentModal] = useState(true);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState(objekat);
  const [textFieldValue3, setTextFieldValue3] = useState(vrsta);
  const [textFieldValue4, setTextFieldValue4] = useState(sorta);
  const [textFieldValue5, setTextFieldValue5] = useState(godiste);
  const [childModal, setChildModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedOption, setSelectedOption] = useState(pol);
  const [selectedComboOption, setSelectedComboOption] = useState(status);
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const fetchDataMain = async () => {
    if(textFieldValue3 === '' ||
      textFieldValue === '' ||
      textFieldValue4 === '' ||
      textFieldValue5 === ''){
        alert("Niste uneli sve podatke!");
    }
    else{
    setData([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://localhost/eagro/farmaEdit.php', { vrsta: textFieldValue3,objekat: textFieldValue,zivotinja:id,pol:selectedOption, sorta:textFieldValue4, status:selectedComboOption, godiste:textFieldValue5 });
      setData(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); 
      alert("Podaci uspešno uneti!");
      
    setTextFieldValue('');
    setTextFieldValue5('');
    setTextFieldValue3('');
    setTextFieldValue4('');
    console.log(id);
    }
  }};

  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://localhost/eagro/filterObjekat.php');
      setData1(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://localhost/eagro/filterZivotinja.php');
      setData2(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://localhost/eagro/filterVrsta.php');
      setData3(result.data);
    } catch (error) {
      console.error(error);
    }
  };

   const fetchData4 = async () => {
    try {
      const result = await axios.post('https://localhost/eagro/filterSorta.php', {vrsta:textFieldValue3});
      setData4(result.data);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);
  
  useEffect(() => {
    fetchData4();
  }, [textFieldValue3]);


  const handleOptionChange = (event) => {
    const value = parseInt(event.target.value);
    if (value===1){
      setSelectedOption('');
    }
    else if (value===2){
      setSelectedOption('M');
    }
    else if (value===3){
      setSelectedOption('Ž');
    }
    console.log(value);
  };
  
  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);


  const handleComboChange = (event) => {
    const value = event.target.value; 
    if (value==="1"){
      setSelectedComboOption('1');
    }
    else if (value==="2"){
      setSelectedComboOption('2');
    }
    else if (value==="3"){
      setSelectedComboOption('3');
    }
    };
  
    useEffect(() => {
      console.log(selectedComboOption);
    }, [selectedComboOption]);



  //PARENT MODAL
    const parentModalClose = () => {
      if (typeof onClose === 'function') {
        onClose();
      }
        setParentModal(false);
    };




  //CHILD MODAL
  const openModal = (data, headers, title,broj) => {
    setChildModal(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);
    console.log(childModal+'   open');
    
  };

  const closeModal = () => {
    setChildModal(false);
    console.log(childModal+'   close');
  };  



  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf1') {
      setTextFieldValue(value2);
    } 
    else if(source==='textf3'){
      setTextFieldValue3(value2);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value2);
    }
  };

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        parentModalClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);


  return (
    <section className='popupSection'>
        <div className='bg'>
        <Modal
          visible={parentModal}
          width='700'
          height='500'
          effect='fadeInUp'
          onClickAway={parentModalClose}
          className='modal'
        >
          <div className='inputPopUpWrapper'>
          <div className='popUpFilteri'>
    
    <h1 className='filteriNaslov'>Unos grla</h1>
    
    <div className="search-container">
          <label htmlFor="textf1">Objekat</label>
          <div className='textFilterWrap'>
          <input
            type="text"
            id="textf1"
            value={textFieldValue}
            onChange={(e) => setTextFieldValue(e.target.value)}
            className='inputFilterEdit'
            disabled
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => openModal(data1, ['id', 'naziv', 'lokacija'], "Naslov 1", 'textf1')}
            className="search-icon"
          />
        </div>
        </div>
    
        <div className="search-container">
          <label htmlFor="textf3">Vrsta</label>
          <div className='textFilterWrap'>
          <input
            type="text"
            id="textf3"
            value={textFieldValue3}
            onChange={(e) => setTextFieldValue3(e.target.value)}
            className='inputFilterEdit'
            disabled
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => openModal(data3, ['id', 'naziv'], "Naslov 3",'textf3')}
            className="search-icon"
          />
        </div></div>
    
        <div className="search-container">
          <label htmlFor="textf4">Sorta</label>
          <div className='textFilterWrap'>
          <input
            type="text"
            id="textf4"
            value={textFieldValue4}
            onChange={(e) => setTextFieldValue4(e.target.value)}
            className='inputFilterEdit'
            disabled
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => openModal(data4, ['id','sorta'], "Naslov 4",'textf4')}
            className="search-icon"
          />
        </div>
        <br/>
        <label htmlFor="textf5">Godište</label>
          <div className='numberFilterWrap'>
          <input
            type="number"
            id="textf5"
            value={textFieldValue5}
            onChange={(e) => setTextFieldValue5(e.target.value)}
            className='numberInputFilter'
          />
        </div>
        </div>
        {childModal && 
          <ChildModalPopUp closeModalO={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
        }
    
        <div className='radioFilteri'>
          <br />
        
          <label>
            <input
              type="radio"
              value="2"
              checked={selectedOption === 'M'}
              onChange={handleOptionChange}
            />
            Muško
          </label>
          <br />
          <label>
            <input
              type="radio"
              value="3"
              checked={selectedOption === 'Ž'}
              onChange={handleOptionChange}
            />
            Žensko
          </label>
        </div>
        <br/>
        <div className='comboFilteri'>
          <label>
            Status: &nbsp;
            <select value={selectedComboOption} onChange={handleComboChange}>
              <option value="1">Aktivno</option>
              <option value="2">Prodato</option>
              <option value="3">Zaklano</option>
            </select>
          </label>
        </div>
        <br />
        <div className='farmaDugmad'>
        <button className='farmaDugme' onClick={fetchDataMain}>Izmena</button>
        
        <button className='farmaDugme' onClick={parentModalClose}>Izlaz</button>
         </div>
      </div>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default PopUpEdit;
