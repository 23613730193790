import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';

function MyComponent() {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data22, setData22] = useState([]);
  const [data5, setData5] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState('1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [datum, setDatum] = useState(null);
  const [datumDo, setDatumDo] = useState(null);
  const [selectedComboOption, setSelectedComboOption] = useState('1');
  const [broj, setBroj] = useState('');
  const [status, setStatus] = useState('1');
  const [roba, setRoba] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [kolone, setKolone] = useState(['id','vrsta', 'sorta', 'lokacija','pol','status']);
  const [stavka, setStavka] = useState([]);



  const fetchDataMain = async () => {
    setIsLoading(true);
 
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/pregledProdaje.php',{racun: textFieldValue1,
      komitent: textFieldValue,
     datumDo: datumDo,
      datum: datum,
    roba:textFieldValue2});
      console.log(result.data);
      setStavka((prevStavka) => result.data); 
      reset();
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); // Disable loading state
    }
  
  };



  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterKomitent.php');
      setData1(result.data);
      console.log(stavka);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };


  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterZivotinja.php');
      setData2(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterRobaProdaja.php');
      setData3(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData22 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterRoba.php');
      setData22(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
    fetchData22();
  }, []);



  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    console.log(stavka);
  }, [stavka]);

  useEffect(() => {
    console.log(kolone);
  }, [kolone]);

  useEffect(() => {
    console.log(data2);
    console.log(data22);
  }, [data2,data22]);
  

  const handleComboChange = (event) => {
    const value = event.target.value;
    if (value === "1") {
      setStatus('1');
      setSelectedComboOption('1');
      setData5(data2); 
      setKolone(['id', 'vrsta', 'sorta', 'lokacija', 'pol', 'status']);
    } else if (value === "2") {
      setSelectedComboOption('2');
      setData5(data22); 
      setKolone(['Skladište', 'Kultura', 'Količina']);
      setStatus('2');
    }
  };
  

  useEffect(() => {
    console.log(selectedComboOption);
  }, [selectedComboOption]);

  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf') {
      setTextFieldValue(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value);
      
    }
    else if(source==='textf4'){
      setTextFieldValue4(value);
    }
    console.log(textFieldValue3);
  };

  
  const reset = async () => {
    setTextFieldValue5('1');
    setTextFieldValue2('');
    setTextFieldValue3('');
    setTextFieldValue4('');
  };

  const handleValueChange = (e) => {
    const value = e.target.value;
    setTextFieldValue3(value);
    setTextFieldValue4((parseFloat(value) * 1.17).toFixed(2));
  };

  
  return (
<div className="wrapperFarma">
  <Sidemenu />
  <div className='filteriShort' >
  
<h1 className='filteriNaslovBreak'>Pregled <br/> prodaje</h1>

<div className="search-container">
    <div className="search-container">
      <div className='datumi'>
        <label htmlFor="textf4">Datum od</label>
          <DatePicker selected={datum}
                onSelectDate={setDatum}
                dateFormat="dd/MM/yyyy"
                isClearable  />
    </div>

    <div className='datumi'>
        <label htmlFor="textf4">Datum do</label>
          <DatePicker selected={datumDo}
                onSelectDate={setDatumDo}
                dateFormat="dd/MM/yyyy"
                isClearable  />
    </div>
    </div>
  
    <div className="search-container">
    <label htmlFor="textf1">Broj računa</label>
      <div className='numberFilterWrap'>
      <input
        type="text"
        id="textf1"
        value={textFieldValue1}
        onChange={(e) => setTextFieldValue1(e.target.value)}
        className='numberInputFilter'
      />
      </div> 
    </div> 

    <div className="search-container">
      <label htmlFor="textf">Komitent</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf"
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data1, ['ID', 'Komitent', 'Mesto'], "Naslov 1", 'textf')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
      <label htmlFor="textf">Roba</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf2"
        value={textFieldValue2}
        onChange={(e) => setTextFieldValue2(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data3, ['Roba'], "Naslov 1", 'textf2')}
        className="search-icon"
      />
    </div>
    </div>

    </div>

    {isModalOpen && (
      <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
    )}

   <br/>
    <div className='farmaDugmad'>
    <button className='farmaDugme' onClick={fetchDataMain}>Pretraga</button>
   
    </div>
    
  </div>



  <div className='farmaTabelaWrapper'>
    
    <table className="farmaTable">
      
      <thead>
        <tr>
          <th className='freez'>Račun</th>
          <th className='freez'>Komitent</th>
          <th className='freez'>Roba</th>
          <th className='freez'>Kolicina</th>
          <th className='freez'>Cena</th>
          <th className='freez'>Ukupno</th>
          <th className='freez'>Datum</th>
        </tr>
      </thead>
      <tbody>
  {Array.isArray(stavka) && stavka.length > 0 ? (
    stavka.map((order) => (
      <tr >
        <td>{order.Račun}</td>
        <td>{order.Komitent}</td>
        <td>{order.Roba}</td>
        <td>{parseFloat(order.Kolicina).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
        <td>{parseFloat(order.Cena).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
        <td>{(parseFloat(order.Kolicina) * parseFloat(order.Cena)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})}</td>
        <td>{new Date(order.Datum).toLocaleDateString('en-GB')}</td>
      </tr>
    ))
  ) : (
    Array.from({ length: 16 }, (_, index) => (
      <tr key={index}>
      </tr>
    ))
  )}
</tbody>

    </table>
    
  {isLoading && <LoadingSpinner />}
  </div>
  
</div>

  );
}

export default MyComponent;
