import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import PopUpImport from '../Accessories/PopUpImportFarma';
import PopUpEdit from '../Accessories/PopUpEditFarma';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import '../App.scss';

function TableRow({ order }) {
  const [openPopUp, setOpenPopUp] = useState(false);


  const closePopUp = () => {
    setOpenPopUp(false);
  };  


  return (
    <tr key={order.id}>
    <td>{order.id}</td>
    <td>{order.vrsta}</td>
    <td>{order.sorta}</td>
    <td>{order.lokacija}</td>
    <td>{order.pol}</td>
    <td>{order.status}</td>
    <td>{order.godiste}</td>
    <td><button className='editButton' onClick={() => setOpenPopUp(true)}>Izmeni</button></td>
    {openPopUp &&  <PopUpEdit onClose={closePopUp} id={order.id} objekat={order.lokacija} vrsta={order.vrsta} sorta={order.sorta} godiste={order.godiste} pol={order.pol} status={order.status}/>}
  </tr>
  );
}
function MyComponent() {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedComboOption, setSelectedComboOption] = useState('1');
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);

  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterObjekat.php');
      setData1(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchDataMain = async () => {
    setData([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/farma.php', { vrsta: textFieldValue3,objekat: textFieldValue,zivotinja:textFieldValue2,pol:selectedOption, sorta:textFieldValue4, status:selectedComboOption, godiste:textFieldValue5 });
      setData(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); // Disable loading state
    }
  };
  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterZivotinja.php');
      setData2(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterVrsta.php');
      setData3(result.data);
    } catch (error) {
      console.error(error);
    }
  };

   const fetchData4 = async () => {
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/filterSorta.php', {vrsta:textFieldValue3});
      setData4(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const reset = async () => {
    setTextFieldValue('');
    setTextFieldValue2('');
    setTextFieldValue3('');
    setTextFieldValue4('');
    console.log(selectedComboOption);
  };

  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);



  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptionChange = (event) => {
    const value = parseInt(event.target.value);
    if (value===1){
      setSelectedOption('');
    }
    else if (value===2){
      setSelectedOption('M');
    }
    else if (value===3){
      setSelectedOption('Ž');
    }
    console.log(value);
  };
  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);
  
  useEffect(() => {
    fetchData4();
  }, [textFieldValue3]);
  

  const handleComboChange = (event) => {
  const value = event.target.value; 
  if (value==="1"){
    setSelectedComboOption('1');
  }
  else if (value==="2"){
    setSelectedComboOption('2');
  }
  else if (value==="3"){
    setSelectedComboOption('3');
  }
  };

  useEffect(() => {
    console.log(selectedComboOption);
  }, [selectedComboOption]);

  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf1') {
      setTextFieldValue(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value2);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value2);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value2);
    }
    console.log(textFieldValue3);
  };

  const closePopUp = () => {
    setOpenPopUp(false);
  };  





  return (
<div className="wrapperFarma">
  <Sidemenu />
  <div className='filteri'>
    
<h1 className='filteriNaslov'>Pregled farme</h1>
  <div className="search-container">
      <label htmlFor="textf2">Životinje</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf2"
        value={textFieldValue2}
        onChange={(e) => setTextFieldValue2(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data2, ['vrsta', 'id', 'sorta', 'lokacija','pol','status'], "Naslov 2",'textf2')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
      <label htmlFor="textf1">Objekat</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf1"
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data1, ['id', 'naziv', 'lokacija'], "Naslov 1", 'textf1')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
      <label htmlFor="textf3">Vrsta</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf3"
        value={textFieldValue3}
        onChange={(e) => setTextFieldValue3(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data3, ['id', 'naziv'], "Naslov 3",'textf3')}
        className="search-icon"
      />
    </div></div>

    <div className="search-container">
      <label htmlFor="textf4">Sorta</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf4"
        value={textFieldValue4}
        onChange={(e) => setTextFieldValue4(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data4, ['id','sorta'], "Naslov 4",'textf4')}
        className="search-icon"
      />
    </div>
    <br/>
    <label htmlFor="textf5">Godište</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf5"
        value={textFieldValue5}
        onChange={(e) => setTextFieldValue5(e.target.value)}
        className='numberInputFilter'
      />
    </div>
    </div>

    {isModalOpen && (
      <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
    )}

    <div className='radioFilteri'>
      <br />
      <label>
        <input
          type="radio"
          value="1"
          checked={selectedOption === ''}
          onChange={handleOptionChange}
        />
        Sve
      </label>
      <br />
      <label>
        <input
          type="radio"
          value="2"
          checked={selectedOption === 'M'}
          onChange={handleOptionChange}
        />
        Muško
      </label>
      <br />
      <label>
        <input
          type="radio"
          value="3"
          checked={selectedOption === 'Ž'}
          onChange={handleOptionChange}
        />
        Žensko
      </label>
    </div>
    <br/>
    <div className='comboFilteri'>
      <label>
        Status: &nbsp;
        <select value={selectedComboOption} onChange={handleComboChange}>
          <option value="1">Aktivno</option>
          <option value="2">Prodato</option>
          <option value="3">Zaklano</option>
        </select>
      </label>
    </div>
    <br />
    <div className='farmaDugmad'>
    <button className='farmaDugme' onClick={fetchDataMain}>Pretraga</button>
    
    <button className='farmaDugme' onClick={reset}>Reset</button>
    </div>
    
    <button className='farmaDugme' onClick={() => setOpenPopUp(true)}>Novi unos</button>
    {openPopUp && <PopUpImport onClose={closePopUp} />}
  </div>

  <div className='farmaTabelaWrapper'>
    <table className="farmaTable">
      
      <thead>
        <tr>
          <th className="freez">Id</th>
          <th className="freez">Vrsta</th>
          <th className="freez">Sorta</th>
          <th className="freez">Lokacija</th>
          <th className="freez">Pol</th>
          <th className="freez">Status</th>
          <th className="freez">Godište</th>
          <th className="freez">Uredi</th>
        </tr>
      </thead>
      <tbody>
  {Array.isArray(data) && data.length > 0 ? (
    data.map((order) => (
      
      <TableRow order={order} key={order.id} />
   
    ))
  ) : (
    Array.from({ length: 16 }, (_, index) => (
      <tr key={index}>
      </tr>
    ))
  )}
</tbody>

    </table>
    
  {isLoading && <LoadingSpinner />}
  </div>
</div>

  );
}

export default MyComponent;
