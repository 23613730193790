import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import PopUpImport from '../Accessories/PopUpImportMehanizacija';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';

function MyComponent() {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data, setData] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [datum, setDatum] = useState(null);
  const [datumDo, setDatumDo] = useState(null);
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);

  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterMeh.php');
      setData1(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterMehKat.php');
      setData2(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataMain = async () => {
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/mehanizacija.php', {
        id: textFieldValue,
        kat: textFieldValue2,
        datum_Od: datum,
        datum_Do: datumDo,
        god1: Number(textFieldValue5),
        god2: Number(textFieldValue3)
      });
      setData(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); 
    }
  };

  const closePopUp = () => {
    setOpenPopUp(false);
  };  

  useEffect(() => {
    fetchData1();
    fetchData2();
  }, []);

  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    console.log(data2);
  }, [data2]);
  
 


  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf') {
      setTextFieldValue(value);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value);
    }
    console.log(textFieldValue3);
  };

  
  return (
<div className="wrapperMehanizacija">
  <Sidemenu />
  <div className='filteriShort'>
  
  <h1 className='filteriNaslov'>Pregled mehanizacije</h1>
    
  <div className="search-container">
        <label htmlFor="textf">Vozilo/Mašina</label>
        <div className='textFilterWrap'>
        <input
          type="text"
          id="textf"
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
          className='inputFilter'
          disabled
        />
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => openModal(data1, ['ID', 'Naziv','Godište', 'Poslednja registracija'], "Naslov 1", 'textf')}
          className="search-icon"
        />
      </div>
  </div>

  <div className="search-container">
        <label htmlFor="textf2">Tip</label>
        <div className='textFilterWrap'>
        <input
          type="text"
          id="textf2"
          value={textFieldValue2}
          onChange={(e) => setTextFieldValue2(e.target.value)}
          className='inputFilter'
          disabled
        />
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => openModal(data2, [ 'Naziv'], "Naslov 1", 'textf2')}
          className="search-icon"
        />
      </div>
  </div>

  <div className="search-container" style={{ marginTop:'-1px'}}>
      <div className='datumi'>
        <label htmlFor="textf4">Registracija</label>
          <DatePicker selected={datum}
                onSelectDate={setDatum}
                dateFormat="dd/MM/yyyy"
                isClearable  />
    </div>

    <div className='datumi'>
          <DatePicker selected={datumDo}
                onSelectDate={setDatumDo}
                dateFormat="dd/MM/yyyy"
                isClearable  />
    </div>
    </div>
  
    <div className="search-container">
    <label htmlFor="textf5">Godište</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf5"
        value={textFieldValue5}
        onChange={(e) => setTextFieldValue5(e.target.value)}
        className='numberInputFilter'
      />
    </div> 
    <br/>
    <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf3"
        value={textFieldValue3}
        onChange={(e) => setTextFieldValue3(e.target.value)}
        className='numberInputFilter'
      />
    </div> 
</div>
  
      {isModalOpen && (
        <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
      )}
  
     <br/>
      <div className='farmaDugmad'>
      <button className='farmaDugme' onClick={fetchDataMain}>Pretraga</button>
      <button className='farmaDugme' onClick={() => setOpenPopUp(true)}>Novi unos</button>
    {openPopUp && <PopUpImport onClose={closePopUp} />}
      </div>
      
    </div>



  <div className='farmaTabelaWrapper'>
    
  <table className="farmaTable">
  <thead>
    <tr>
      <th className='freez'>ID</th>
      <th className='freez'>Naziv</th>
      <th className='freez'>Tip</th>
      <th className='freez'>Godište</th>
      <th className='freez'>Poslednja registracija</th>
    </tr>
  </thead>
  <tbody>
    {Array.isArray(data) && data.length > 0 ? (
      data.map((order) => {
        const lastRegistrationDate = new Date(order["Poslednja registracija"]);
        const today = new Date();
        const oneYearInMillis = 365 * 24 * 60 * 60 * 1000; // 1 year in milliseconds
        const timeDifference = today - lastRegistrationDate;

        const isOverOneYear = timeDifference >= oneYearInMillis;

        const rowClassName = isOverOneYear ? 'overOneYear' : '';

        return (
          <tr key={order.ID} className={rowClassName}>
            <td>{order.ID}</td>
            <td>{order.Naziv}</td>
            <td>{order.Tip}</td>
            <td>{order.Godište}</td>
            <td>{lastRegistrationDate.toLocaleDateString('en-GB')}</td>
          </tr>
        );
      })
    ) : (
      Array.from({ length: 16 }, (_, index) => (
        <tr key={index}>
        </tr>
      ))
    )}
  </tbody>
</table>

    
  {isLoading && <LoadingSpinner />}
  </div>
  
</div>

  );
}

export default MyComponent;
