import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default function MyDatePicker({ onSelectDate,date }) {
    const [selectedDate, setSelectedDate] = useState(null);
  
    const handleDateChange = (date) => {
      setSelectedDate(date);
      onSelectDate(date);
    };
  
    useEffect(() => {
    }, [selectedDate, onSelectDate]);
    return (
      <DatePicker
      className='datepicker'
        showIcon
        todayButton="Today"
        isClearable={true}
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText={date}
      />
    );
  }
  
  