import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';

function PopUp({ closeModalO, data, columnHeaders, title, onValueReceived,broj  }) {
  const [visibleO, setVisibleO] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    if (typeof closeModalO === 'function') {
      closeModalO();
    }
  };

  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);

  const handleCellDoubleClick = (row, source) => {
    const firstCellValue = row[columnHeaders[0]]; 
    const secondCellValue = row[columnHeaders[1]]; 
    closeModal();
    if (typeof onValueReceived === 'function') {
      onValueReceived(firstCellValue,secondCellValue, source);
    }
  };

 

  return (
    <section className='popupSection'>
      <div className='bg'>
      <Modal
      visible={isOpen}
      width='650'
      height='400'
      effect='fadeInUp'
      onClickAway={closeModal}
      className='modalChild'
    >
          <div className='PopUpWrapperChild'>
            <div className='naslovnaTraka'>
            <h1 className='PopUpTitleChild'>{title}</h1>
            <button onClick={closeModal} className='closeChild'>X</button>
            </div>
            <div className='PopUpTableWrapperChild'>
              <table className='PopUpTable'>
                <thead>
                  <tr>
                    {columnHeaders.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(data) && data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        {columnHeaders.map((header, columnIndex) => (
                          <td
                            key={columnIndex}
                            onDoubleClick={() =>
                              handleCellDoubleClick(item,broj)
                            }
                          >
                            {item[header]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={columnHeaders.length}></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default PopUp;
