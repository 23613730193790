import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';

function ScreenSize() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="screen-size">
      Screen size: {screenWidth}px x {screenHeight}px
    </div>
  );
}
function MyComponent() {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState('');
  const [textFieldValue6, setTextFieldValue6] = useState('');
  const [max, setMax] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(null);


  const insertF = async () => {
    setData([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/dnevnikF.php', { zivotinja: textFieldValue2,datum: selectedFromDate,sredstvo:textFieldValue,radnja:textFieldValue6,kolicina:textFieldValue5 });
      setData(result.data);
      console.log(result.data);
      fetchDataPretraga();
      fetchData1();
      reset();
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); 
    }
  };

  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterSredstvoF.php');
      setData1(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };


  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterZivotinja.php');
      setData2(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterZivotinja.php');
      setData3(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData4 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterRadnjaZ.php');
      setData5(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataPretraga = async () => {
    setData4([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/filterDnevnikF.php',{zivotinja:textFieldValue3});
      setData4(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false);
    }
  };


  const reset = async () => {
    setTextFieldValue('');
    setTextFieldValue2('');
    setTextFieldValue3('');
    setTextFieldValue4('');
    setTextFieldValue5('');
    setTextFieldValue6('');
  };

  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);



  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };
  
  
  const closeModal = () => {
    setIsModalOpen(false);
  };
  

  

  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf1') {
      setTextFieldValue(value);
      setTextFieldValue5(value2);
      setMax(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value2);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value2);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value2);
    }
    else if(source==='textf6'){
      setTextFieldValue6(value);
    }
    console.log(textFieldValue5);
  };

  useEffect(() => {
    console.log(selectedFromDate);
    }, [selectedFromDate]);




  return (
<div className="wrapperFarma">
  <Sidemenu />
  <div className='filteri'>
    
<h1 className='filteriNaslov'>Dnevnik farme</h1>


    <div className="search-container">
    <label htmlFor="textf2">Životinja</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf2"
        value={textFieldValue2}
        onChange={(e) => setTextFieldValue2(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data2, ['vrsta', 'id', 'sorta', 'lokacija','pol','status'], "Naslov 2",'textf2')}
        className="search-icon"
      />
    </div>
    <div className='datumi'>
          <label htmlFor="textf4">Datum</label>
            <DatePicker selected={selectedFromDate}
                  onSelectDate={setSelectedFromDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable  />
    </div>
    <br/>
      <label htmlFor="textf1">Sredstvo</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf1"
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data1, ['sredstvo', 'kolicina','JM'], "Naslov 1", 'textf1')}
        className="search-icon"
      />
    </div>

    <br/>
    <label htmlFor="textf6">Radnja</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf5"
        value={textFieldValue6}
        onChange={(e) => setTextFieldValue6(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data5, ['Radnja'], "Naslov 1", 'textf6')}
        className="search-icon"
      />
    </div>
    </div>
    

    <div className="search-container">
    <label htmlFor="textf5">Količina</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf5"
        value={textFieldValue5}
        onChange={(e) => {
          const newValue = parseFloat(e.target.value);
          if (newValue < (parseFloat(max)+1) || isNaN(newValue)) {
            setTextFieldValue5(newValue);
          }
        }}
        className='numberInputFilter'
      />
    </div>
 
    </div>

    {isModalOpen && (
      <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
    )}
    <br />
    
    <div className='farmaDugmad'>
 <br />
    <button className='farmaDugme' onClick={insertF}>Unos</button>
    
    <button className='farmaDugme' onClick={reset}>Reset</button>
     </div>
     <div className="search-container">
      <label htmlFor="textf3">Šifra životinje</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf4"
        value={textFieldValue3}
        onChange={(e) => setTextFieldValue3(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data3,  ['vrsta', 'id', 'sorta', 'lokacija','pol','status'],  "Naslov 1",'textf3')}
        className="search-icon"
      />
    </div>
    </div>
 <br />
    <button className='farmaDugme' onClick={fetchDataPretraga}>Pretraga</button>
  </div>

  <div className='farmaTabelaWrapper'>
    <table className="farmaTable">
      
    <thead>
        <tr>
          <th className="freez">Id</th>
          <th className='freez'>Objekat</th>
          <th className='freez'>Sredstvo</th>
          <th className='freez'>Radnja</th>
          <th className='freez'>Datum</th>
          <th className='freez'>Količina</th>
        </tr>
      </thead>
      <tbody>
  {Array.isArray(data4) && data4.length > 0 ? (
    data4.map((order) => (
      <tr>
        <td>{order.zivotinja}</td>
        <td>{order.objekat}</td>
        <td>{order.sredstvo}</td>
        <td>{order.radnja}</td>
      <td>{new Date(order.datum).toLocaleDateString('en-GB')}</td>
        <td>{order.kolicina}</td>
      </tr>
    ))
  ) : (
    Array.from({ length: 16 }, (_, index) => (
      <tr key={index}>
      </tr>
    ))
  )}
</tbody>

    </table>
    
  {isLoading && <LoadingSpinner />}
  </div>
</div>

  );
}

export default MyComponent;
