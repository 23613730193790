import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from './Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ChildModalPopUp from './PopUp2';
import Modal from 'react-awesome-modal';
import DatePicker from '../Accessories/DatePicker';
import { parseISO } from 'date-fns';

function PopUpEdit({ onClose, usev, kultura,parcela,sorta,datum_setve, datum_zetve, status, povrsina }) {
  const [parentModal, setParentModal] = useState(true);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState(kultura);
  const [textFieldValue3, setTextFieldValue3] = useState(parcela);
  const [textFieldValue4, setTextFieldValue4] = useState(sorta);
  const [textFieldValue5, setTextFieldValue5] = useState(povrsina);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedComboOption, setSelectedComboOption] = useState(status);
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(new Date(datum_setve));
  const [selectedToDate, setSelectedToDate] = useState(new Date(datum_zetve));
  const [childModal, setChildModal] = useState(false);


  const fetchDataMain = async () => {
    if(textFieldValue3 === '' ||
      textFieldValue5 === '' ||
      textFieldValue4 === '' ||
      textFieldValue2 === '' ||
      selectedFromDate === Date('') ||
      selectedToDate === Date('') ){
        alert("Niste uneli sve podatke!");
    }
    else{
    setData([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://localhost/eagro/useviEdit.php', { id:usev, kultura: textFieldValue2,parcela: textFieldValue3,sorta:textFieldValue4,datum_setve:selectedFromDate, datum_zetve:selectedToDate, status:selectedComboOption, povrsina:textFieldValue5 });
      setData(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false);
      alert("Podaci uspešno uneti!");
      
    setTextFieldValue2('');
    setTextFieldValue5('');
    setTextFieldValue3('');
    setTextFieldValue4('');
    setSelectedFromDate(null);
    setSelectedToDate(null);
    }
  }};

  
  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://localhost/eagro/filterKultura.php');
      setData2(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://localhost/eagro/filterParcela.php');
      setData3(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData4 = async () => {
    try {
      const result = await axios.post('https://localhost/eagro/filterSortaU.php',{vrsta:textFieldValue2});
      setData4(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);


  useEffect(() => {
    fetchData4();
  }, [textFieldValue2]);

 
  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);


  const handleComboChange = (event) => {
    const value = event.target.value; 
    if (value==="1"){
      setSelectedComboOption('1');
    }
    else if (value==="2"){
      setSelectedComboOption('2');
    }
    else if (value==="3"){
      setSelectedComboOption('3');
    }
    };
  
    useEffect(() => {
      console.log(selectedComboOption);
    }, [selectedComboOption]);




  
  const reset = async () => {
    setTextFieldValue2('');
    setTextFieldValue3('');
    setTextFieldValue4(''); 
    setTextFieldValue5(''); 
    setSelectedFromDate(null);
    setSelectedToDate(null);
    console.log(selectedFromDate);
    console.log(selectedToDate);
  };

  
  
  useEffect(() => {
    console.log(selectedToDate);
    console.log(selectedFromDate);
    }, [selectedFromDate, selectedToDate]);




  //PARENT MODAL
    const parentModalClose = () => {
      if (typeof onClose === 'function') {
        onClose();
      }
        setParentModal(false);
    };




  //CHILD MODAL
  const openModal = (data, headers, title,broj) => {
    setChildModal(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);
    console.log(childModal+'   open');
    
  };

  const closeModal = () => {
    setChildModal(false);
    console.log(childModal+'   close');
  };  



  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf1') {
      setTextFieldValue(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value2);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value2);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value2);
    }
  };

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  return (
    <section className='popupSection'>
        <div className='bg'>
        <Modal
          visible={parentModal}
          width='700'
          height='500'
          effect='fadeInUp'
          onClickAway={parentModalClose}
          className='modal'
        >
          <div className='inputPopUpWrapper'>
          <div className='popUpFilteri'>
    <h1 className='filteriNaslov'>Unos useva</h1>
    
        <div className="search-container">
          <label htmlFor="textf2">Kultura</label>
          <div className='textFilterWrap'>
          <input
            type="text"
            id="textf2"
            value={textFieldValue2}
            onChange={(e) => setTextFieldValue2(e.target.value)}
            className='inputFilterEdit'
            disabled
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => openModal(data2, ['id', 'kultura'], "Naslov 2", 'textf2')}
            className="search-icon"
          />
        </div>
        </div>
    
        <div className="search-container">
          <label htmlFor="textf3">Parcela</label>
          <div className='textFilterWrap'>
          <input
            type="text"
            id="textf3"
            value={textFieldValue3}
            onChange={(e) => setTextFieldValue3(e.target.value)}
            className='inputFilterEdit'
            disabled
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => openModal(data3, ['id', 'parcela'], "Naslov 3",'textf3')}
            className="search-icon"
          />
        </div></div>
    
        <div className="search-container">
          <label htmlFor="textf4">Sorta</label>
          <div className='textFilterWrap'>
          <input
            type="text"
            id="textf4"
            value={textFieldValue4}
            onChange={(e) => setTextFieldValue4(e.target.value)}
            className='inputFilterEdit'
            disabled
          />
          <FontAwesomeIcon
            icon={faSearch}
            onClick={() => openModal(data4, ['rb','sorta'], "Naslov 4",'textf4')}
            className="search-icon"
          />
        </div>
        <br/>
        <label htmlFor="textf5">Površina (u m2)</label>
          <div className='numberFilterWrap'>
          <input
            type="number"
            id="textf5"
            value={textFieldValue5}
            onChange={(e) => setTextFieldValue5(e.target.value)}
            className='numberInputFilter'
          />
        </div>
    
        <div className='datumi'>
          <label htmlFor="textf4">Datum setve</label>
            <DatePicker selected={selectedFromDate}
                  date={selectedFromDate ? selectedFromDate.toLocaleDateString('en-GB') : ''}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  
                   />
    
            <label htmlFor="textf4">Datum žetve</label>
            <DatePicker selected={selectedToDate}
                  date={selectedToDate ? selectedToDate.toLocaleDateString('en-GB') : ''}
                  onSelectDate={setSelectedToDate} 
                  dateFormat="dd/MM/yyyy"
                  isClearable  />
                  
        </div>
        </div>
    
    
        {childModal && 
          <ChildModalPopUp closeModalO={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
        }
    
     
        <br/>
        <div className='comboFilteri'>
          <label>
            Status: &nbsp;
            <select value={selectedComboOption} onChange={handleComboChange}>
              <option value="1">Aktivno</option>
              <option value="2">Neaktivno</option>
            </select>
          </label>
        </div>
        <br />
        <div className='farmaDugmad'>
        <button className='farmaDugme' onClick={fetchDataMain}>Unos</button>
        
        <button className='farmaDugme' onClick={parentModalClose}>Izlaz</button>
        </div>
      </div>
      </div>
     
        </Modal>
      </div>
    </section>
  );
}

export default PopUpEdit;
