import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from './Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ChildModalPopUp from './PopUp2';
import DatePicker from '../Accessories/DatePicker';
import Modal from 'react-awesome-modal';

function PopUpImport({ onClose}) {
  const [parentModal, setParentModal] = useState(true);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [datum, setDatum] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState('');
  const [textFieldValue6, setTextFieldValue6] = useState('');
  const [childModal, setChildModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const insert = async () => {
    if(datum === null ||
      textFieldValue2 === '' ||
      textFieldValue5 === ''){
        alert("Niste uneli sve podatke!");
    }
    else{
    setData([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://localhost/eagro/mehanizacijaImport.php', {
        naziv:textFieldValue3,
        kat: textFieldValue2,
        datum_Od:datum,
        god1:textFieldValue5,
      reg:textFieldValue6});
      setData(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); 
      alert("Podaci uspešno uneti!");
      
    setTextFieldValue2('');
    setTextFieldValue5('');
    setTextFieldValue3('');
    setTextFieldValue4('');
    setTextFieldValue6('');
    }
  }};

  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://localhost/eagro/filterMehKat.php');
      setData2(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    fetchData2();
  }, []);



  //PARENT MODAL
    const parentModalClose = () => {
      if (typeof onClose === 'function') {
        onClose();
      }
        setParentModal(false);
    };




  //CHILD MODAL
  const openModal = (data, headers, title,broj) => {
    setChildModal(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);
    console.log(childModal+'   open');
    
  };

  const closeModal = () => {
    setChildModal(false);
    console.log(childModal+'   close');
  };  



  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf1') {
      setTextFieldValue(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value2);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value2);
    }
  };

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        parentModalClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);


  return (
    <section className='popupSection'>
       <div className='bg'>
        <Modal
          visible={parentModal}
          width='500'
          height='400'
          effect='fadeInUp'
          onClickAway={parentModalClose}
          className='modal'
        >
          <div className='inputPopUpWrapper'>
          <div className='popUpFilteri' style={{height:'450px'}}>
  <h1 className='filteriNaslov'>Pregled mehanizacije</h1>
  

  <div className="search-container">
        <label htmlFor="textf2">Tip</label>
        <div className='textFilterWrap'>
        <input
          type="text"
          id="textf2"
          value={textFieldValue2}
          onChange={(e) => setTextFieldValue2(e.target.value)}
          className='inputFilter'
          disabled
        />
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => openModal(data2, [ 'Naziv'], "Naslov 1", 'textf2')}
          className="search-icon"
        />
      </div>
  </div>

  <div className="search-container">
    <label htmlFor="textf3">Naziv</label>
      <div className='numberFilterWrap'>
      <input
        type="text"
        id="textf3"
        value={textFieldValue3}
        onChange={(e) => setTextFieldValue3(e.target.value)}
        className='numberInputFilter'
      />
    </div> 
</div>

<div className="search-container">
    <label htmlFor="textf6">Registarske oznake</label>
      <div className='numberFilterWrap'>
      <input
        type="text"
        id="textf6"
        value={textFieldValue6}
        onChange={(e) => setTextFieldValue6(e.target.value)}
        className='numberInputFilter'
      />
    </div> 
</div>

  <div className="search-container" style={{ marginTop:'-1px'}}>
      <div className='datumi'>
        <label htmlFor="textf4">Registracija</label>
          <DatePicker selected={datum}
                onSelectDate={setDatum}
                dateFormat="dd/MM/yyyy"
                isClearable  />
    </div>
    </div>
  
    <div className="search-container">
    <label htmlFor="textf5">Godište</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf5"
        value={textFieldValue5}
        onChange={(e) => setTextFieldValue5(e.target.value)}
        className='numberInputFilter'
      />
    </div> 
</div>
  
     
{childModal && 
          <ChildModalPopUp closeModalO={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
        }
  
     <br/>
      <div className='farmaDugmad'>
      <button className='farmaDugme' onClick={insert}>Unos</button>
        <button className='farmaDugme' onClick={parentModalClose}>Izlaz</button>
      </div>
      
    </div>
    </div>
        </Modal>
    </div>
    </section>
  );
}

export default PopUpImport;
