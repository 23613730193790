import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import PopUpImport from '../Accessories/PopUpImportMehanizacija';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';

function MyComponent() {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data4, setData4] = useState([]);
  const [data, setData] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [datum, setDatum] = useState(null);
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);

  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterMeh.php');
      setData1(result.data);
      setData4(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };


  const importDataMain = async () => {
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/dnevnikMehanizacije.php', {
        id: textFieldValue,
        datum_Od: datum,
        opis: textFieldValue3
      });
      console.log(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); 
    }
  };

  const pretraga = async () => {
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/filterDnevnikMehanizacije.php', {
        id: textFieldValue4
      });
      setData(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); 
    }
  };

  const closePopUp = () => {
    setOpenPopUp(false);
  };  

  useEffect(() => {
    fetchData1();
  }, []);

  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    console.log(data2);
  }, [data2]);
  
 


  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf') {
      setTextFieldValue(value);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value);
    }
    console.log(textFieldValue3);
  };

  
  return (
<div className="wrapperMehanizacija">
  <Sidemenu />
  <div className='filteriShort'>
  
  <h1 className='filteriNaslov'>Dnevnik mehanizacije</h1>
    
  <div className="search-container">
        <label htmlFor="textf">Vozilo/Mašina</label>
        <div className='textFilterWrap'>
        <input
          type="text"
          id="textf"
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
          className='inputFilter'
          disabled
        />
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => openModal(data1, ['ID', 'Naziv','Godište', 'Poslednja registracija'], "Naslov 1", 'textf')}
          className="search-icon"
        />
      </div>
  </div>


  <div className="search-container" style={{ marginTop:'-1px'}}>
      <div className='datumi'>
        <label htmlFor="textf4">Datum</label>
          <DatePicker selected={datum}
                onSelectDate={setDatum}
                dateFormat="dd/MM/yyyy"
                isClearable  />
    </div>
    </div>
  
  
    <div className="search-container">
    <label htmlFor="textf3">Opis</label>
      <div className='numberFilterWrap' style={{height:'80px'}}>
      <textarea
        type="text"
        id="textf3"
        value={textFieldValue3}
        onChange={(e) => setTextFieldValue3(e.target.value)}
        className='numberInputFilter'
        style={{height:'70px',resize: 'none'}}
      />
    </div> 
</div>
  
      {isModalOpen && (
        <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
      )}
  
     <br/>
      <div className='farmaDugmad'>
      <button className='farmaDugme' onClick={importDataMain}>Unos</button>
    {openPopUp && <PopUpImport onClose={closePopUp} />}
      </div>
      
      <div className="search-container">
        <label htmlFor="textf4">Vozilo/Mašina</label>
        <div className='textFilterWrap'>
        <input
          type="text"
          id="textf4"
          value={textFieldValue4}
          onChange={(e) => setTextFieldValue4(e.target.value)}
          className='inputFilter'
          disabled
        />
        <FontAwesomeIcon
          icon={faSearch}
          onClick={() => openModal(data4, ['ID', 'Naziv','Godište', 'Poslednja registracija'], "Naslov 1", 'textf4')}
          className="search-icon"
        />
      </div>
  </div>
<br/>
  <div className='farmaDugmad'>
      <button className='farmaDugme' onClick={pretraga}>Traži</button>
      </div>

    </div>



  <div className='farmaTabelaWrapper'>
    
  <table className="farmaTable">
  <thead>
    <tr>
      <th className='freez'>Registarske oznake</th>
      <th className='freez'>Naziv</th>
      <th className='freez'>Datum</th>
      <th className='freez'>Opis</th>
    </tr>
  </thead>
  <tbody>
    {Array.isArray(data) && data.length > 0 ? (
      data.map((order) => (

        <tr>
        <td>{order.reg}</td>
        <td>{order.Naziv}</td>
        <td>{new Date(order.datum).toLocaleDateString('en-GB')}</td>
        <td style={{ whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
          {order.Opis}
        </td>
      </tr>
      
      )  
      )
    ) : (
      Array.from({ length: 16 }, (_, index) => (
        <tr key={index}>
        </tr>
      ))
    )}
  </tbody>
</table>

    
  {isLoading && <LoadingSpinner />}
  </div>
  
</div>

  );
}

export default MyComponent;
