import LoginPage from './Komponente/LoginPage';
import HomePage from './Komponente/Home';
import API from './Komponente/API'
import Graph from './Komponente/GraphPage';
import Farma from './Komponente/Farma';
import Usevi from './Komponente/Usevi';
import DnevnikF from './Komponente/DnevnikFarma';
import DnevnikU from './Komponente/DnevnikUsevi';
import Excel from './Komponente/Excel';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Prodaja from './Komponente/Prodaja';
import Nabavka from './Komponente/Nabavka';
import DnevnikM from './Komponente/DnevnikMehanizacije';
import Mehanizacija from './Komponente/PregledMehanizacije';
import Lager from './Komponente/Lager';
import PregledNabavka from './Komponente/PregledNabavki';
import PregledProdaje from './Komponente/PregledProdaje';
import PrivateRoutes from './PrivateRoutes'

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<HomePage />} />
          <Route path="/farma" element={<Farma />} />
          <Route path="/graphic-page" element={<Graph />} />
          <Route path="/api-page" element={<API />} />
          <Route path="/usevi" element={<Usevi />} />
          <Route path="/dnevnik-farme" element={<DnevnikF/>} />
          <Route path="/dnevnik-useva" element={<DnevnikU/>} />
          <Route path="/prodaja" element={<Prodaja/>} />
          <Route path="/nabavka" element={<Nabavka/>} />
          <Route path="/skladiste" element={<Lager/>} />
          <Route path="/mehanizacija" element={<Mehanizacija/>} />
          <Route path="/dnevnik-mehanizacije" element={<DnevnikM/>} />
          <Route path="/pregled-nabavki" element={<PregledNabavka/>} />
          <Route path="/pregled-prodaje" element={<PregledProdaje/>} />
          <Route path="/excel" element={<Excel/>} />
        </Route>
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}


export default MyRoutes;
