import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';

function MyComponent() {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data5, setData5] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState('1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [datum, setDatum] = useState(null);
  const [datumDo, setDatumDo] = useState(null);
  const [selectedComboOption, setSelectedComboOption] = useState('1');
  const [broj, setBroj] = useState('');
  const [status, setStatus] = useState('1');
  const [roba, setRoba] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [kolone, setKolone] = useState(['id','vrsta', 'sorta', 'lokacija','pol','status']);
  const [stavka, setStavka] = useState([]);
  const [faktura, setFaktura] = useState('');



  const fetchDataMain = async () => {
    setIsLoading(true);
 
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/lager.php',{skladiste: textFieldValue1,
      roba: textFieldValue2});
      console.log(result.data);
      setStavka((prevStavka) => result.data); 
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); // Disable loading state
    }
  };

  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterLager.php');
      setData1(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterRobaKultura.php');
      setData2(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    fetchData1();
    fetchData2();
  }, []);



  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    console.log(stavka);
  }, [stavka]);

  useEffect(() => {
    console.log(kolone);
  }, [kolone]);

  

  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf1') {
      setTextFieldValue1(value);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value);
      setRoba(value2);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value);
      
    }
    else if(source==='textf4'){
      setTextFieldValue4(value);
    }
    console.log(textFieldValue3);
  };


  return (
<div className="wrapperLager">
  <Sidemenu />
  <div className='filteri'  style={{ height: '390px', marginTop:'-234px'}}>
  
<h1 className='filteriNaslov'>Stanje skladišta</h1>

<br/> 
<div className="search-container">
      <label>Skladište</label>
      <br/>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf1"
        value={textFieldValue1}
        onChange={(e) => setTextFieldValue1(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data1 ,['Skladište','Lokacija'] , "Naslov 3",'textf1')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
      <label htmlFor="textf2">Roba</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf2"
        value={textFieldValue2}
        onChange={(e) => setTextFieldValue2(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data2, ['Roba'], "Naslov 1", 'textf2')}
        className="search-icon"
      />
    </div>
</div>

    {isModalOpen && (
      <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
    )}

   <br/>
    <div className='farmaDugmad'>
    <button className='farmaDugme' onClick={fetchDataMain}>Pretraga</button>
   
    </div>
    
  </div>


  <div className='farmaTabelaWrapper'>
    
    <table className="farmaTable">
      
      <thead>
        <tr>
          <th className='freez'>Skladište</th>
          <th className='freez'>Roba</th>
          <th className='freez'>Količina</th>
        </tr>
      </thead>
      <tbody>
  {Array.isArray(stavka) && stavka.length > 0 ? (
    stavka.map((order) => (
      <tr >
        <td>{order.Skladište}</td>
        <td>{order.Roba}</td>
        <td>{order.Količina}</td>
      </tr>
    ))
  ) : (
    Array.from({ length: 16 }, (_, index) => (
      <tr key={index}>
      </tr>
    ))
  )}
</tbody>

    </table>
    
  {isLoading && <LoadingSpinner />}
  </div>
  
</div>

  );
}

export default MyComponent;
