import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function MyComponent() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const auth = localStorage.getItem("auth") === "true";
    if(auth && auth !== null) {
        navigate("/home");
    }
}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://eagro.artivagency.com/baza/login.php', { username:email, password:password });
      if (response.data === 'Success') {
        console.log(response.data);
        localStorage.setItem('auth', true);

        navigate('/home'); 
      } else {
        alert('Pogrešni podaci!');
      }
    } catch (error) {
      alert('Greška sa konekcijom!');
      console.error(error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleButtonClick() {
    window.open('https://www.facebook.com', '_blank');
  }

  return (
    <div className="loginWrapper">
      <div className="formWrapper">
        <form className="loginForma" noValidate>
          <div className="form-group">
            <p className="formaNaslov">Prijavite se</p>
            <input
              type="text"
              className="formaUsername"
              placeholder="Korisničko ime"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <input
              id="password-field"
              type={showPassword ? 'text' : 'password'}
              className="formaSifra"
              placeholder="Lozinka"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <span
              id="toggle-password"
              className={`eye ${showPassword ? 'active' : ''}`}
              onClick={togglePasswordVisibility}
            >
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                size="1x"
                style={{ color: '#ffffff' }}
              />
            </span>
            <button type="submit" className="formaBtn" onClick={handleSubmit}>
             Prijava
            </button>
            <br />
            <p className="loginKontakt">- Kontaktirajte nas -</p>
            <br />
            <div className="kontaktBtns">
              <div className="kontaktFB">
                <button className="kontakt">E-mail</button>
              </div>
              <div className="kontaktEM">
                <button className="kontakt" onClick={handleButtonClick}>
                  Facebook
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MyComponent;
