import MyRoutes from './Route';
import './App.scss';

function App() {
  return (
      <div>
      <MyRoutes />
    </div>
    );
}

export default App;
