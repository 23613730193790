import React, {useEffect} from "react";
import { Link, useNavigate } from 'react-router-dom';

export default function Sidemenu(){
    const navigate = useNavigate();

    useEffect(() => {
      const disableBackButton = (e) => {
        e.preventDefault();
        window.history.forward();
      };
  
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', disableBackButton);
  
      return () => {
        window.removeEventListener('popstate', disableBackButton);
      };
    }, []);
  
    const handleLogout = () => {
      localStorage.setItem("auth", false);
      navigate("/");
    };
    return(
        <div className="hamburger-menu">
            <input id="menu__toggle" type="checkbox" />
            <label className="menu__btn" htmlFor="menu__toggle">
                <span></span>
            </label>

            <ul className="menu__box">
                <li>
                    <Link to="/home" className="menu__item">Početna</Link>
                </li> 
                <li>
                    <Link to="/farma" className="menu__item">Farma</Link>
                </li> 
                <li>
                    <Link to="/usevi" className="menu__item">Usevi</Link>
                </li>
                <li>
                    <Link to="/dnevnik-farme" className="menu__item">Dnevnik Farme</Link>
                </li>  
                <li>
                    <Link to="/dnevnik-useva" className="menu__item">Dnevnik Useva</Link>
                </li>  
                <li>
                    <Link to="/prodaja" className="menu__item">Prodaja</Link>
                </li>
                <li>
                    <Link to="/nabavka" className="menu__item">Nabavka</Link>
                </li>
                <li>
                    <Link to="/pregled-prodaje" className="menu__item">Pregled prodaje</Link>
                </li>
                <li>
                    <Link to="/pregled-nabavki" className="menu__item">Pregled nabavki</Link>
                </li>
                <li>
                    <Link to="/skladiste" className="menu__item">Stanje skladišta</Link>
                </li>  
                <li>
                    <Link to="/mehanizacija" className="menu__item">Pregled mehanizacije</Link>
                </li>
                
                <li>
                    <Link to="/dnevnik-mehanizacije" className="menu__item">Dnevnik mehanizacije</Link>
                </li>  
                <li>
                    <Link to="/" className="menu__item" onClick={handleLogout}>Odjava</Link>
                </li>
            </ul>
        </div>
    )
}
