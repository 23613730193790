import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';

function MyComponent() {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data5, setData5] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState('');
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [textFieldValue5, setTextFieldValue5] = useState('1');
  const [textFieldValue6, setTextFieldValue6] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [datum, setDatum] = useState(null);
  const [selectedComboOption, setSelectedComboOption] = useState('1');
  const [broj, setBroj] = useState('');
  const [roba, setRoba] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [stavka, setStavka] = useState([]);
  const [faktura, setFaktura] = useState('');


  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterKomitent.php');
      setData1(result.data);
      console.log(stavka);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDataMain = async () => {
    setIsLoading(true);
    if(stavka.length===0){
      alert('Niste uneli nijednu stavku!');
      setIsLoading(false);
    }
    else{
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/nabavka.php',stavka);
      console.log(result.data);
      setFaktura(result.data.max_id);
      setStavka([]);
      reset();
    } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false); // Disable loading state
    }
  }
  };

  useEffect(() => {
    console.log('BROJ', faktura);
  }, [faktura]);

  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterGrupaRobe.php');
      setData2(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterLager.php');
      setData3(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
  }, []);



  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    console.log(stavka);
  }, [stavka]);


  useEffect(() => {
    console.log(data2);
    console.log(data3);
  }, [data2,data3]);
  
 


  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf') {
      setTextFieldValue(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value2);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value2);
      
    }
    else if(source==='textf4'){
      setTextFieldValue4(value);
    }
    console.log(textFieldValue3);
  };


  const unosStavke = () => {
    

    setStavka([
      ...stavka,
      {
        racun: textFieldValue1,
        komitent: textFieldValue,
        robaid: textFieldValue2,
        skladiste: textFieldValue3,
        kolicina: textFieldValue5,
        cena: textFieldValue6,
        cenaPDV: textFieldValue4,
        datum: datum
      }
    ]);
  
    reset();
  };
  
  const reset = async () => {
    setTextFieldValue5('1');
    setTextFieldValue2('');
    setTextFieldValue3('');
    setTextFieldValue4('');
  };

  const handleValueChange = (e) => {
    const value = e.target.value;
    setTextFieldValue6(value);
    setTextFieldValue4((parseFloat(value) * 1.17).toFixed(2));
  };
  

  
  return (
<div className="wrapperFarma">
  <Sidemenu />
  <div className='filteri' style={{ height: '700px', marginTop: '80px'}}>
  
<h1 className='filteriNaslov'>Nabavka</h1>


<div className="search-container">
    <div className='datumi'>
      <label htmlFor="textf4">Datum</label>
        <DatePicker selected={datum}
              onSelectDate={setDatum}
              dateFormat="dd/MM/yyyy"
              isClearable  />
  </div>
  </div>

<div className="search-container">
<label htmlFor="textf1">Broj računa</label>
      <div className='numberFilterWrap'>
      <input
        type="text"
        id="textf1"
        value={textFieldValue1}
        onChange={(e) => setTextFieldValue1(e.target.value)}
        className='numberInputFilter'
      />
    </div>
    </div>
  
<div className="search-container">
      <label htmlFor="textf">Komitent</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf"
        value={textFieldValue}
        onChange={(e) => setTextFieldValue(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data1, ['ID', 'Komitent', 'Mesto'], "Naslov 1", 'textf')}
        className="search-icon"
      />
    </div>
</div>

<div className="search-container">
      <label>Roba</label>
      <br/>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf2"
        value={textFieldValue2}
        onChange={(e) => setTextFieldValue2(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data2 ,['id','naziv','jm'] , "Naslov 2",'textf2')}
        className="search-icon"
      />
    </div>
    </div>
    
    <div className="search-container">
      <label>Skladište</label>
      <br/>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf3"
        value={textFieldValue3}
        onChange={(e) => setTextFieldValue3(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data3 ,['id','Skladište','Lokacija'] , "Naslov 3",'textf3')}
        className="search-icon"
      />
    </div>
    </div>

<div className="search-container">
    <label htmlFor="textf5">Količina</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf5"
        value={textFieldValue5}
        onChange={(e) => setTextFieldValue5(e.target.value)}
        className='numberInputFilter'
      />
    </div> 
</div>

<div className="search-container">
    <label htmlFor="textf6">Cena bez PDV-a</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf6"
        value={textFieldValue6}
        onChange={handleValueChange}
        className='numberInputFilter'
      />
    </div>
</div>

<div className="search-container">
    <label htmlFor="textf4">Cena sa PDV-om</label>
      <div className='numberFilterWrap'>
      <input
        type="number"
        id="textf4"
        value={textFieldValue4}
        onChange={(e) => setTextFieldValue4(e.target.value)}
        className='numberInputFilter'
      />
    </div>
    </div>

   

    {isModalOpen && (
      <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
    )}

   <br/>
    <div className='farmaDugmad'>
    <button className='farmaDugme' onClick={unosStavke}>Unos stavke</button>
   
    </div>
    
  </div>

  <div className='farmaTabelaWrapper' style={{ marginTop:'-100px'}}>
    
  <button className='farmaDugme' onClick={fetchDataMain}  >Potvrdi</button>
  <table className="farmaTable">
  <thead>
    <tr>
      <th className='freez'>Roba</th>
      <th className='freez'>Skladište</th>
      <th className='freez'>Količina</th>
      <th className='freez'>Cena</th>
      <th className='freez'>Cena sa PDV-om</th>
      <th className='freez'>Datum</th>
    </tr>
  </thead>
  <tbody>
    {Array.isArray(stavka) && stavka.length > 0 ? (
      <>
        {stavka.map((order) => (
          <tr key={order.robaid}>
            <td>{order.robaid}</td>
            <td>{order.skladiste}</td>
            <td>{order.kolicina}</td>
            <td>{order.cena}</td>
            <td>{order.cenaPDV}</td>
            <td>{new Date(order.datum).toLocaleDateString('en-GB')}</td>
          </tr>
        ))}
        <tr>
          <td colSpan="3"><strong>Ukupno</strong></td>
          <td><strong>
            {stavka.reduce((total, order) => total + parseFloat(order.cena), 0).toFixed(2)}</strong>
          </td>
          <td><strong>
            {stavka.reduce((total, order) => total + parseFloat(order.cenaPDV), 0).toFixed(2)}</strong>
          </td>
        </tr>
      </>
    ) : (
      Array.from({ length: 16 }, (_, index) => (
        <tr key={index}>
        </tr>
      ))
    )}
  </tbody>
</table>

    
  {isLoading && <LoadingSpinner />}
  </div>
</div>

  );
}

export default MyComponent;
