import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidemenu from '../Accessories/Sidemenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PopUp from '../Accessories/PopUp';
import PopUpImport from '../Accessories/PopUpImportUsevi';
import '../App.scss';
import LoadingSpinner from '../Accessories/LoadingSpinner';
import DatePicker from '../Accessories/DatePicker';
import MapModal from '../Accessories/MapModal';
import PopUpEdit from '../Accessories/PopUpEditUsevi';

function TableRow({ order }) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);


  const closePopUp = () => {
    setOpenPopUp(false);
  };  

  const handleOpenMapModal = () => {
    setIsMapModalOpen(true);
  };

  const handleCloseMapModal = () => {
    setIsMapModalOpen(false);
  };

  return (
    <tr key={order.id}>
      <td>{order.id}</td>
      <td>{order.kultura}</td>
      <td>{order.sorta}</td>
      <td onClick={handleOpenMapModal}>{order.parcela}</td>
      <td>{new Date(order.datum_setve).toLocaleDateString('en-GB')}</td>
      <td>{order.datum_zetve
            ? new Date(order.datum_zetve).toLocaleDateString('en-GB')
            : ''}</td>
      <td>{order['povrsina(m2)']}
      <MapModal
        isOpen={isMapModalOpen}
        onRequestClose={handleCloseMapModal}
        lat={parseFloat(order.lat)}
        lng={parseFloat(order.lon)}
      />
      </td>
    <td><button className='editButton' onClick={() => setOpenPopUp(true)}>Izmeni</button></td>
    {openPopUp &&  <PopUpEdit onClose={closePopUp} usev={order.id} kultura={order.kultura} parcela={order.parcela} sorta={order.sorta} datum_setve={order.datum_setve} datum_zetve={order.datum_zetve} status={order.status} povrsina={order['povrsina(m2)']}/>}
  </tr>
  );
}

function MyComponent() {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [textFieldValue1, setTextFieldValue1] = useState('');
  const [textFieldValue2, setTextFieldValue2] = useState('');
  const [textFieldValue3, setTextFieldValue3] = useState('');
  const [textFieldValue4, setTextFieldValue4] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedComboOption, setSelectedComboOption] = useState('1');
  const [broj, setBroj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFromDateS, setSelectedFromDateS] = useState(null);
  const [selectedToDateS, setSelectedToDateS] = useState(null);
  const [selectedFromDateZ, setSelectedFromDateZ] = useState(null);
  const [selectedToDateZ,setSelectedToDateZ] = useState(null);
  const [openPopUp, setOpenPopUp] = useState(false);

  const fetchDataMain = async () => {
    setData([]);
    setIsLoading(true);
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/usevi.php', { usev:textFieldValue1, kultura: textFieldValue2,parcela: textFieldValue3, sorta:textFieldValue4, datum_setveOd:selectedFromDateS, datum_zetveOd:selectedFromDateZ,datum_setveDo:selectedToDateS, datum_zetveDo:selectedToDateZ, status:selectedComboOption });
      setData(result.data);
      console.log(result.data);
      console.log(selectedFromDateZ);
      console.log(selectedToDateZ);
   } catch (error) {
      console.error(error);
      alert("Greška sa konekcijom!");
    } finally {
      setIsLoading(false);
    }
  };
  const fetchData1 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterUsev.php');
      setData1(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData2 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterKultura.php');
      setData2(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData3 = async () => {
    try {
      const result = await axios.get('https://eagro.artivagency.com/baza/filterParcela.php');
      setData3(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData4 = async () => {
    try {
      const result = await axios.post('https://eagro.artivagency.com/baza/filterSortaU.php',{vrsta:textFieldValue2});
      setData4(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData1();
    fetchData2();
    fetchData3();
    fetchData4();
  }, []);


  useEffect(() => {
    fetchData4();
  }, [textFieldValue2]);

  const openModal = (data, headers, title,broj) => {
    setIsModalOpen(true);
    setModalData(data);
    setColumnHeaders(headers);
    setTitle(title);
    setBroj(broj);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const handleComboChange = (event) => {
    const value = event.target.value; 
    if (value==="1"){
      setSelectedComboOption('1');
    }
    else if (value==="2"){
      setSelectedComboOption('2');
    }
    };
  
    useEffect(() => {
      console.log(selectedComboOption);
    }, [selectedComboOption]);



  const handleValueReceived = (value,value2, source) => {
    console.log(value);
    if (source === 'textf1') {
      setTextFieldValue1(value2);
    } 
    else if(source==='textf2'){
      setTextFieldValue2(value2);
    }
    else if(source==='textf3'){
      setTextFieldValue3(value2);
    }
    else if(source==='textf4'){
      setTextFieldValue4(value2);
    }
  };

  
  const reset = async () => {
    setTextFieldValue1('');
    setTextFieldValue2('');
    setTextFieldValue3('');
    setTextFieldValue4(''); 
    console.log(data);
  };

  
  
  useEffect(() => {
    console.log(selectedToDateS);
    console.log(selectedFromDateS);
    console.log(selectedToDateZ);
    console.log(selectedFromDateZ);
    }, [selectedFromDateS, selectedToDateS, selectedFromDateZ, selectedToDateZ]);


    const closePopUp = () => {
      setOpenPopUp(false);
    };  

    
    

  return (
<div className="wrapperUsevi">
  <Sidemenu />
  <div className='filteri-usevi'>
    
<h1 className='filteriNaslov'>Pregled useva</h1>
  <div className="search-container">
      <label htmlFor="textf1">Šifra useva</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf1"
        value={textFieldValue1}
        onChange={(e) => setTextFieldValue1(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data1, ['kultura', 'id', 'sorta', 'lokacija','datum_setve','datum_zetve','status'], "Naslov 1",'textf1')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
      <label htmlFor="textf2">Kultura</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf2"
        value={textFieldValue2}
        onChange={(e) => setTextFieldValue2(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data2, ['id', 'kultura'], "Naslov 2", 'textf2')}
        className="search-icon"
      />
    </div>
    </div>

    <div className="search-container">
      <label htmlFor="textf3">Parcela</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf3"
        value={textFieldValue3}
        onChange={(e) => setTextFieldValue3(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data3, ['id', 'parcela'], "Naslov 3",'textf3')}
        className="search-icon"
      />
    </div></div>

    <div className="search-container">
      <label htmlFor="textf4">Sorta</label>
      <div className='textFilterWrap'>
      <input
        type="text"
        id="textf4"
        value={textFieldValue4}
        onChange={(e) => setTextFieldValue4(e.target.value)}
        className='inputFilter'
        disabled
      />
      <FontAwesomeIcon
        icon={faSearch}
        onClick={() => openModal(data4, ['rb','sorta'], "Naslov 4",'textf4')}
        className="search-icon"
      />
    </div>

    <div className='datumi'>
      <label htmlFor="textf4">Period setve</label>
        <DatePicker selected={selectedFromDateS}
              onSelectDate={setSelectedFromDateS}
              dateFormat="dd/MM/yyyy"
              isClearable  />
        <DatePicker selected={selectedToDateS}
              onSelectDate={setSelectedToDateS} 
              dateFormat="dd/MM/yyyy"
              isClearable  />
    </div>
    <div className='datumi'>
      <label htmlFor="textf4">Period žetve</label>
        <DatePicker selected={selectedFromDateZ}
              onSelectDate={setSelectedFromDateZ}
              dateFormat="dd/MM/yyyy"
              isClearable  />
        <br/>
        <DatePicker selected={selectedToDateZ}
              onSelectDate={setSelectedToDateZ} 
              dateFormat="dd/MM/yyyy"
              isClearable  />
    </div>
    </div>


    {isModalOpen && (
      <PopUp closeModal={closeModal} data={modalData} columnHeaders={columnHeaders} title={title} onValueReceived={handleValueReceived} broj={broj}/>
    )}

 
    <br/>
    <div className='comboFilteri'>
      <label>
        Status: &nbsp;
        <select value={selectedComboOption} onChange={handleComboChange}>
          <option value="1">Aktivno</option>
          <option value="2">Neaktivno</option>
        </select>
      </label>
    </div>
    <br />
    <div className='farmaDugmad'>
    <button className='farmaDugme' onClick={fetchDataMain}>Pretraga</button>
    <button className='farmaDugme' onClick={reset}>Reset</button>
    </div>
    <button className='farmaDugme' onClick={() => setOpenPopUp(true)}>Novi unos</button>
    {openPopUp && <PopUpImport onClose={closePopUp} />}
  </div>

  <div className='useviTabelaWrapper'>
    <table className="farmaTable">
      
      <thead>
        <tr>
          <th className='freez'>Id</th>
          <th className='freez'>Kultura</th>
          <th className='freez'>Sorta</th>
          <th className='freez'>Parcela&nbsp;
          <FontAwesomeIcon icon={faInfoCircle} title="Kliknite na parcelu za prikazivanje mape"/></th>
          <th className='freez'>Datum setve</th>
          <th className='freez'>Datum žetve </th>
          <th className='freez'>Površina (m&sup2;)</th>
          <th className="freez">Uredi</th>
        </tr>
      </thead>
      <tbody>
  {Array.isArray(data) && data.length > 0 ? (
    data.map((order) => (
      <TableRow order={order} key={order.id} />
    ))
  ) : (
    <tr>
      <td colSpan="8">Nema rezultata za unesene filtere</td>
    </tr>
  )}
</tbody>
    </table>
    
  {isLoading && <LoadingSpinner />}
  </div>
</div>

  );
}

export default MyComponent;
