import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';

function PopUp({ closeModal, data, columnHeaders, title, onValueReceived,broj  }) {
  const [visibleO, setVisibleO] = useState(true);

  const handleModalClose = () => {
    setVisibleO(false);
    if (typeof closeModal === 'function') {
      closeModal();
    }
  };

  const handleCellDoubleClick = (row, source) => {
    const firstCellValue = row[columnHeaders[0]]; 
    const secondCellValue = row[columnHeaders[1]]; 
    handleModalClose();
    if (typeof onValueReceived === 'function') {
      onValueReceived(firstCellValue,secondCellValue, source);
    }
  };
  

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        handleModalClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  return (
    <section className='popupSection'>
      <div className='bg'>
        <Modal
          visible={visibleO}
          width='720'
          height='550'
          effect='fadeInUp'
          onClickAway={handleModalClose}
          className='modal'
        >
          <div className='PopUpWrapper'>
            <h1 className='PopUpTitle'>{title}</h1>
            <div className='PopUpTableWrapper'>
              <table className='PopUpTable'>
                <thead>
                  <tr>
                    {columnHeaders.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(data) && data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        {columnHeaders.map((header, columnIndex) => (
                          <td
                            key={columnIndex}
                            onDoubleClick={() =>
                              handleCellDoubleClick(item,broj)
                            }
                          >
                            {item[header]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={columnHeaders.length}></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default PopUp;
