import React, { useState } from 'react';
import Sidemenu from '../Accessories/Sidemenu';





function HomePage() {

  return (
    
    <div className='wrapperHome'>
     <Sidemenu/>
   <h1 className='homeNaslov'>Dobrodošli u eAgro ERP sistem!</h1>
   <br/><br/><br/>

   <div className='tabovi'>

   <div class="col-lg-2 mb-5 mb-lg-0 mx-3 tab">
  <div class="bg-white prva px-3 pt-5 pb-2 rounded-lg shadow" style={{ overflowWrap: 'break-word'}}>
    <h1 class="h6 text-uppercase font-weight-bold text-center mb-4" >Upravljanje proizvodnjom</h1>

    <div class="custom-separator my-4 mx-auto bg-primary"></div>

    <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
      <li class="mb-3">
       <a className="link" href="/farma">Farma</a></li>
      <li class="mb-3">
       <a className="link" href="/usevi">Usevi</a></li>
      <li class="mb-3">
       <a className="link" href="/dnevnikFarma">Dnevnik farme</a></li>
      <li class="mb-3">
       <a className="link" href="/dnevnikUsevi">Dnevnik useva</a></li>
    </ul>
  </div>
</div>

<div className="col-lg-2 mb-5 mb-lg-0 mx-3 tab">
  <div className="bg-white druga px-3 pt-5 pb-2 rounded-lg shadow" style={{ overflowWrap: 'break-word' }}>
    <h1 className="h6 text-uppercase font-weight-bold text-center mb-4">Upravljanje poslovanjem</h1>

    <div className="custom-separator my-4 mx-auto bg-primary"></div>

    <ul className="list-unstyled my-5 text-small text-left font-weight-normal">
      <li className="mb-3">
        <a className="link" href="/prodaja">Prodaja</a>
      </li>
      <li className="mb-3">
        <a className="link" href="/nabavka">Nabavka</a>
      </li>
      <li className="mb-3">
        <a className="link" href="/pregled-prodaje">Pregled prodaja</a>
      </li>
      <li className="mb-3">
        <a className="link" href="/pregled-nabavki">Pregled nabavki</a>
      </li>
    </ul>
  </div>
</div>

<div className="col-lg-2 mb-5 mb-lg-0 mx-3 tab">
  <div className="bg-white treca px-3 pt-5 pb-2 rounded-lg shadow" style={{ overflowWrap: 'break-word' }}>
    <h1 className="h6 text-uppercase font-weight-bold text-center mb-4">Upravljanje zalihama</h1>

    <div className="custom-separator my-4 mx-auto bg-primary"></div>

    <ul className="list-unstyled my-5 text-small text-left font-weight-normal">
      <li className="mb-3">
        <a className="link" href="/skladiste">Stanje zaliha</a>
      </li>
      <li className="mb-3 text-white">
        &nbsp;
      </li>
      <li className="mb-3 text-white">
        &nbsp;
      </li>
      <li className="mb-3 text-white">
        &nbsp;
      </li>
    </ul>
  </div>
</div>

<div className="col-lg-2 mb-5 mb-lg-0 mx-3 tab">
  <div className="bg-white cetvrta px-3 pt-5 pb-2 rounded-lg shadow" style={{ overflowWrap: 'break-word' }}>
    <h1 className="h6 text-uppercase font-weight-bold text-center mb-4">Upravljanje mehanizacijom</h1>

    <div className="custom-separator my-4 mx-auto bg-primary"></div>

    <ul className="list-unstyled my-5 text-small text-left font-weight-normal">
      <li className="mb-3">
        <a className="link" href="/mehanizacija">Pregled mehanizacije</a>
      </li>
      <li className="mb-3 text-white">
        &nbsp;
      </li>
      <li className="mb-3 text-white">
        &nbsp;
      </li>
      <li className="mb-3 text-white">
        &nbsp;
      </li>
    </ul>
  </div>
</div>


      </div>
      </div>
);
}
export default HomePage;
