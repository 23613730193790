import React, { Component } from 'react';
import Modal from 'react-modal';
import GoogleMapReact from 'google-map-react';

Modal.setAppElement(document.body);

class MapModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [props.lat, props.lng],
      zoom: 15
    };
  }

  handleMapChange = (map) => {
    if (map.center && typeof map.center.lat === 'function' && typeof map.center.lng === 'function') {
      const newCenter = {
        lat: map.center.lat(),
        lng: map.center.lng(),
      };
      this.setState({
        center: newCenter,
        zoom: map.zoom
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.isOpen !== this.props.isOpen ||
      nextProps.lat !== this.props.lat ||
      nextProps.lng !== this.props.lng ||
      nextState.center !== this.state.center ||
      nextState.zoom !== this.state.zoom
    );
  }

  render() {
    const { isOpen, onRequestClose, lat, lng } = this.props;
    const { center, zoom } = this.state;

    return (
      <div className='mapa'>
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            },
            content: {
              position: 'absolute',
              top: '80px',
              left: '80px',
              right: '80px',
              bottom: '80px',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px'
            }
          }}>
          <div style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyC4rtUO5fLNvRfekgKQ2lLA6wbD0BVR0kM' }}
              defaultCenter={center}
              defaultZoom={zoom}
              onChange={this.handleMapChange}  
              options={(maps) => ({
                mapTypeId: maps.MapTypeId.SATELLITE
              })}
            >
              <Marker lat={lat} lng={lng} />
            </GoogleMapReact>
          </div>
        </Modal>
      </div>
    );
  }
}

const Marker = () => <div className="marker"></div>;

export default MapModal;