import { useEffect, useState } from "react";
import * as XLSX from "xlsx";


function Excel() {

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      
      // Perform multiplication and store the result
      const multipliedData = parsedData.map(row => {
        const fourthColumn = row[Object.keys(row)[3]];
        const fifthColumn = row[Object.keys(row)[4]];
        const multiplicationResult = fourthColumn + fifthColumn;
        return { ...row, multiplicationResult };
      });
      
      setData(multipliedData);
    };
  }
  const handleFileTwoUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      
      // Perform multiplication and store the result
      const multipliedData = parsedData.map(row => {
        const fourthColumn = row[Object.keys(row)[3]];
        const fifthColumn = row[Object.keys(row)[4]];
        const multiplicationResult = fourthColumn + fifthColumn;
        return { ...row, multiplicationResult };
      });
      
      setData2(multipliedData);
    };
  }
  const obrada = () => {
    setData3(data[0]*data2[0]);
  };
  

  useEffect(() => {
   setData3();
  }, []);

  return (
    <div className="App">

      <input 
        type="file" 
        accept=".xlsx, .xls" 
        onChange={handleFileUpload} 
      />
      <input 
        type="file" 
        accept=".xlsx, .xls" 
        onChange={handleFileTwoUpload} 
      />
       <input 
        type="submit" 
        onClick={obrada} 
      />
       <input 
        type="text" 
        value={data3}
        onChange={(e) => setData3(e.target.value)} 
      />
{data.length > 0 && (
  <table className="table">
    <thead>
      <tr>
        {Object.keys(data[0]).map((key) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row, index) => (
        <tr key={index}>
          {Object.values(row).map((value, index) => (
            <td key={index}>{value}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)}


    </div>
  );
}

export default Excel;
